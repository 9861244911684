const menuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links"
    },
    {
      title: "Portfolio",
      url: "portfolio",
      cName: "nav-links"
    },
    {
      title: "Contact",
      url: "#",
      cName: "nav-links"
    },
  ];
  
  export default menuItems;
  