import React from 'react';
import './Portfolio.css';

export default function PortfolioComponent() {
    return (
        <div className="portfolio-container">
            <h2 className="portfolio-title">Current Work</h2>
            <div className="projects">
                <div className="project">
                    <h3>Website Portfolio</h3>
                    <img src="https://cdn.discordapp.com/attachments/1250093258135306370/1254834206555770912/Screenshot_2024-06-24_at_17.23.03.png?ex=667aeeec&is=66799d6c&hm=26124576209428d7d97d290919bc389aae9413160b331c88f34054498582855d&" alt="Image for Project 1" />
                    <p>Portfolio Website Made With React.js</p>
                    <a href="https://github.com/jrobz00/Portfolio" className="view-project-button">View Project</a>                </div>
                <div className="project">
                    <h3>Project 2</h3>
                    <img src="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=" alt="Image for Project 2" />
                    <p>Description of project 2</p>
                    <button className="view-project-button">View Project</button>
                </div>
                <div className="project">
                    <h3>Project 3</h3>
                    <img src="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=" alt="Image for Project 3" />
                    <p>Description of project 3</p>
                    <button className="view-project-button">View Project</button>
                </div>
            </div>
        </div>
    );
}
