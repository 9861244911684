import React from 'react';
import Header from '../Components/Header';
import HeroBanner from '../Components/HeroBanner';


export default function Homepage() {
  return (
    <>
      <Header />
      <HeroBanner />
    </>
  );
}
