import HeaderComponent from '../Components/Header'; // Renamed import
import PortfolioComponent from '../Components/PortfolioComponent';

export default function Portfolio() { // Renamed function
  return (
    <>
      <HeaderComponent />
      <PortfolioComponent />
    </>
  );
}
