import React from 'react';
import './HeroBanner.css'; // Assuming you have a CSS file for styling

const HeroBanner = () => {
  return (
    <div className="hero-banner">
      <div className="hero-content">
        <h1 className="hero-title">jrobz00</h1>
        <p className="hero-subtitle">Front-End Developer</p>
      </div>
    </div>
  );
};

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-content">
        <h1 className="about-title">About Me</h1>
        <p className="about-text">
         I'm in my final year studying Computer Science BSc (Hons).
        </p>
      </div>
      <div className="about-image">
        <img src="https://via.placeholder.com/150" alt="Placeholder" />
      </div>
    </div>
  );
};

const SkillsSection = () => {
  // Assuming your skills are stored in an array
  const skills = ['JavaScript', 'React.js', 'HTML', 'CSS', 'Node.js'];

  return (
    <div className="skills-section">
      <h1 className="skills-title">Skills</h1>
      <div className="skills-list">
        {skills.map((skill, index) => (
          <div className="skill-item" key={index}>
            {skill}
          </div>
        ))}
      </div>
    </div>
  );
};

const Homepage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <AboutSection />
      <SkillsSection />
    </div>
  );
};

export default Homepage;
